'use client';
import { v1_cancel, v1_check, v1_close } from '@/services/utils/svg';
import { useEffect } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

const ToastNotify = () => {
    // useEffect(() => {
    //     const getToastMain = document?.querySelector('.commonParent');
    //     let attempts = 0;
    //     let timer: any;
    //     const dismissToast = () => {
    //         if (attempts < 3) {
    //             toast.dismiss();
    //             attempts++;
    //             // Check if .toastMain still exists after dismissing

    //             if (getToastMain) {
    //                 clearTimeout(timer);
    //                 timer = setTimeout(dismissToast, 5000);
    //             }
    //         } else {
    //             clearTimeout(timer);
    //         }
    //     };

    //     timer = setTimeout(dismissToast, 5000);

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, []);

    return (
        <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerClassName="toastMain"
            toastOptions={{
                className: 'parent_notify_box',
                duration: 3000,
                success: {
                    duration: 1500,
                    className: 'commonParent successParent'
                },
                error: {
                    duration: 1500,
                    className: 'commonParent errorParent'
                }
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ message }: any) => (
                        <div className="notify_box">
                            {t.type === 'success' ? (
                                <span className="close" onClick={() => toast.dismiss(t.id)}>
                                    {v1_check}
                                </span>
                            ) : t.type === 'error' ? (
                                <span className="close" onClick={() => toast.dismiss(t.id)}>
                                    {v1_cancel}
                                </span>
                            ) : (
                                // <span className="default" onClick={() => toast.dismiss(t.id)}>
                                //     {v1_check}
                                // </span>
                                ''
                            )}
                            <div className="message">{message}</div>
                        </div>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
};

export default ToastNotify;
