'use client';

import dynamic from 'next/dynamic';
import Loading from '@/components/atoms/loading';

export const Button = dynamic(() => import('@/lab-test/atoms/button'));
export const Spinner = dynamic(() => import('@/lab-test/atoms/spinner'));
export const CancellationModal = dynamic(() => import('@/lab-test/molecules/cancellation-modal'));
export const NoItem = dynamic(() => import('@/lab-test/molecules/no-item'));
export const BookModal = dynamic(() => import('@/lab-test/molecules/book-modal'));
export const LabTestCard = dynamic(() => import('@/lab-test/molecules/lab-test-card'));
export const ModiFiedHeader = dynamic(() => import('@/lab-test/molecules/modified-header'));
export const LabTestDetails = dynamic(() => import('@/lab-test/organisms/lab-test-details'));

export const ShowTests = dynamic(() => import('@/lab-test/atoms/show-tests'));
export const LabTests = dynamic(() => import('@/lab-test/templates/lab-test/tests'), { loading: () => <Loading overlayFixed /> });
export const PatientForm = dynamic(() => import('@/lab-test/molecules/patient-form'));
export const FloatingCart = dynamic(() => import('@/lab-test/molecules/floating-cart'));
export const CategoryTemplate = dynamic(() => import('@/lab-test/templates/lab-test/category'));
export const LabTestCart = dynamic(() => import('@/lab-test/templates/lab-test/lab-test-cart'));

export const LabTestCheckout = dynamic(() => import('@/lab-test/organisms/lab-test-checkout'));
export const Overview = dynamic(() => import('@/lab-test/molecules/overview'));
export const Faq = dynamic(() => import('@/lab-test/organisms/faq'));
export const LabTestCheckoutSuccess = dynamic(() => import('@/lab-test/organisms/lab-test-checkout-success'));
export const Header = dynamic(() => import('@/components/templates/layout/header'));
export const LabSearchField = dynamic(() => import('@/lab-test/atoms/lab-search-field'));
export const SearchList = dynamic(() => import('@/lab-test/organisms/search-list'));
export const PatientReports = dynamic(() => import('@/lab-test/templates/patient-reports'));
export const ReportsList = dynamic(() => import('@/lab-test/organisms/reports-list'));
export const OrdersList = dynamic(() => import('@/lab-test/organisms/orders-list'));
export const PatientsList = dynamic(() => import('@/lab-test/organisms/patients-list'));
