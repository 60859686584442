'use client';
import { createContext, useContext, useState, useEffect } from 'react';

const CategoryStateContext = createContext<any>(undefined);

export const CategoryStateProvider = ({ children }: any) => {
    const [categoryData, setCategoryData] = useState<any>(null);
    const [activeId, setActiveId] = useState<any>(null);
    const [catLoading, setCatLoading] = useState(true);

    const [tId, setTId] = useState<any>(null);
    const [subTaxonomy, setSubTaxonomy] = useState<any>(null);
    // useEffect(() => {

    // }, []);

    return (
        <CategoryStateContext.Provider
            value={{
                categoryData,
                setCategoryData,
                catLoading,
                setCatLoading,
                activeId,
                setActiveId,
                tId,
                setTId,
                subTaxonomy,
                setSubTaxonomy
            }}
        >
            {children}
        </CategoryStateContext.Provider>
    );
};

export const useCategoryStateContext = () => {
    const context = useContext(CategoryStateContext);
    if (!context) {
        throw new Error('use category must be used within a CategoryStateProvider');
    }
    return context;
};
